import React, { useState } from 'react';
import "./contactModal.css"
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, TextField, Typography } from '@mui/material';
import { Grid } from '@mui/material';
import { useMutation } from '@apollo/client';
import { contactFormMutation } from '../../operations/mutations';
import ReCAPTCHA from "react-google-recaptcha";

interface ContactModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: () => void;
}

const ContactModal: React.FC<ContactModalProps> = ({ open, onClose, onSubmit }) => {

    const [contactUs] = useMutation(contactFormMutation.CONTACT_US_MUTATION);
    const [verified, setVerified] = useState(false)

    const [formData, setFormData] = useState({
        fullname: '',
        email: '',
        telephone: '',
        message: ''
    });

    const [errors, setErrors] = useState({
        fullname: '',
        email: '',
    });

    const handleCLose = () => {
        onClose && onClose()
    }

    const handleChange = (e: any) => {
        setFormData({
            ...formData, [e.target.name]: e.target.value
        })
    }

    const onChange = (value: any) => {
        console.log("Captcha value:", value);
        setVerified(true);
      }

    const handleSubmit = async () => {
        // Validate form fields
        let formValid = true;
        const newErrors = {
            fullname: '',
            email: '',
        };

        if (!formData.fullname.trim()) {
            formValid = false;
            newErrors.fullname = 'Please enter your full name';
        }

        if (!formData.email.trim()) {
            formValid = false;
            newErrors.email = 'Please enter your email';
        } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
            formValid = false;
            newErrors.email = 'Please enter a valid email address';
        }

        if (formValid) {
            try {
                const response = await contactUs({
                    variables: {
                        message: formData.message,
                        email: formData.email,
                        telephone: formData.telephone,
                        fullname: formData.fullname
                    },
                });
                console.log(response.data);
                onSubmit && onSubmit()
            } catch (error) {
                console.error(error);
            }
        } else {
            setErrors(newErrors);
        }
    };

    return (
        <React.Fragment>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
            Open alert dialog
          </Button> */}
            <Dialog
                open={open}
                onClose={handleCLose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Contact Us"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} className="input-field-wrap">
                                    <TextField
                                        placeholder="Full Name*"
                                        required
                                        fullWidth
                                        id="fullname"
                                        name="fullname"
                                        value={formData.fullname}
                                        onChange={handleChange}
                                        autoComplete="off"
                                        error={!!errors.fullname}
                                        helperText={errors.fullname}
                                    />
                                </Grid>
                                <Grid item xs={12} className="input-field-wrap">
                                    <TextField
                                        placeholder="Email Address*"
                                        required
                                        fullWidth
                                        id="email"
                                        name="email"
                                        autoComplete="off"
                                        value={formData.email}
                                        onChange={handleChange}
                                        error={!!errors.email}
                                        helperText={errors.email}
                                    />
                                </Grid>
                                <Grid item xs={12} className="input-field-wrap">
                                    <TextField
                                        placeholder="Mobile Number*"
                                        required
                                        fullWidth
                                        id="telephone"
                                        name="telephone"
                                        autoComplete="off"
                                        value={formData.telephone}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} className="input-field-wrap">
                                    <TextField
                                        placeholder="Message*"
                                        required
                                        fullWidth
                                        id="message"
                                        name="message"
                                        autoComplete="off"
                                        value={formData.message}
                                        multiline
                                        rows={4}
                                        maxRows={10}
                                        onChange={handleChange}
                                    />

                                </Grid>
                                <Grid item xs={12} className="input-field-wrap">
                                    <ReCAPTCHA
                                        // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" Testing
                                        // sitekey="6LdVc3IpAAAAAAGiKvPcuwHNdpLXilxwadOYqBh-"
                                        // sitekey="6LeKI3UpAAAAACOf9qgBjG6w7FJ4tOUuRT12sJeY"
                                        sitekey='6LdXsHcpAAAAANWq9ymrOmOWD9AV8ZHdhpNh5avY'
                                        
                                        onChange={onChange}
                                    />
                                    <Button variant="contained" disabled={!verified} sx={{ backgroundColor: "#00B4D7", width: "100%" }} className='add-btn contact-submit-btn' onClick={handleSubmit}>
                                        <Typography color="white" style={{ textTransform: "capitalize" }}>
                                            Submit
                                        </Typography>
                                    </Button>
                                </Grid>

                            </Grid>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

export default ContactModal