import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import { InputLabel } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useMutation } from "@apollo/client";
import * as mutations from "../../../operations/mutations";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
function ChangePasswordModal(props: any) {
  const { t } = useTranslation();
  const [confPassword, setConfPassword] = useState("");
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
  });
  const loggedInToken = localStorage.getItem("loggedInCust");
  const isStrongPassword = (password: any) => {
    const strongPasswordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/;
    return strongPasswordRegex.test(password);
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleConfPassChange = (event: any) => {
    setConfPassword(event.target.value);
  };
  const [changePassword] = useMutation(
    mutations.customerMutation.CHANGE_PASSWORD_MUTATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const form = new FormData();
    form.append("currentPassword", formData.currentPassword);
    form.append("newPassword", formData.newPassword);

    changePassword({
      variables: formData,
    })
      .then((response) => {
        // Handle the response here
        props.handleModalClose();
      })
      .catch((error) => {
        // Handle errors
        console.error(error);
      });
  };

  return (
    <>
      <Modal
        open={props.isModalOpen}
        onClose={props.handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="change_pwd-modal"    
      >
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          autoComplete="off"
          sx={style}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                {t("auth.changePwdModal.old_pwd")}
                <span style={{ color: "#EA0C0C" }}>*</span>
              </InputLabel>
              <TextField
                autoComplete="off"
                name="currentPassword"
                required
                fullWidth
                type="password"
                id="currentPassword"
                placeholder="Current Password"
                value={formData.currentPassword}
                onChange={handleChange}
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                {t("auth.changePwdModal.new_pwd")}
                <span style={{ color: "#EA0C0C" }}>*</span>
              </InputLabel>
              <TextField
                required
                fullWidth
                name="newPassword"
                label="newPassword"
                type="password"
                id="newPassword"
                autoComplete="off"
                value={formData.newPassword}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="change_pwd_strength">
                {t("auth.changePwdModal.pwd_strength")}&nbsp;:&nbsp;
                {formData.newPassword.length === 0 ? (
                  <span>{t("auth.changePwdModal.no_pwd")}</span>
                ) : isStrongPassword(formData.newPassword) ? (
                  <span style={{ color: "green" }}>
                    {t("auth.changePwdModal.strng_pwd")}
                  </span>
                ) : (
                  <span style={{ color: "red" }}>
                    {t("auth.changePwdModal.weak_pwd")}
                  </span>
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                {t("auth.changePwdModal.conf_pwd")}
                <span style={{ color: "#EA0C0C" }}>*</span>
              </InputLabel>
              <TextField
                required
                fullWidth
                name="confpassword"
                placeholder="Confirm Password"
                type="password"
                id="confpassword"
                autoComplete="off"
                value={confPassword}
                onChange={handleConfPassChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" disableElevation type="submit">
                {t("auth.changePwdModal.save_btn")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
export default ChangePasswordModal;
