import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTranslation } from "react-i18next";

function AccountInfo() {
  const { t } = useTranslation();
  return (
    <>
      <Card sx={{ boxShadow: "none" }}>
        <CardContent>
          <Box
            sx={{
              width: "100%",
              bgcolor: "#F0F2F2",
              mb: 4,
              p: 2,
              textAlign: "initial",
            }}
          >
            <Typography
              variant="h5"
              component="h5"
              sx={{ fontWeight: "600", fontSize: "20px" }}
            >
              {t("buyer_dashboard.accountInfo.newsletter")}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              bgcolor: "#F7F6F6",
              mb: 4,
              border: "1px solid #D2CDCD",
              p: 2,
              textAlign: "initial",
            }}
          >
            <Typography
              variant="h6"
              component="h6"
              sx={{ fontSize: "18px", fontWeight: "500" }}
            >
              {t("buyer_dashboard.accountInfo.subscription")}
            </Typography>
          </Box>
          <Box
            sx={{ width: "100%", bgcolor: "#fff", mb: 4, textAlign: "initial" }}
          >
            <FormControlLabel
              control={<Checkbox />}
              label="Allow remote shopping assistance"
            />
          </Box>
          <Box
            sx={{ width: "100%", bgcolor: "#fff", mb: 4, textAlign: "initial" }}
          >
            <Button variant="contained" disableElevation>
              {t("buyer_dashboard.accountInfo.save_btn")}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
export default AccountInfo;
