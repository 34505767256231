import React, { useContext, useEffect } from "react";
import { CountryContext } from "./MainComponent";
import Loading from "./Layout/loader"

const SubdomainRedirect = () => {
  const { store } = useContext(CountryContext);
  const host = window.location.hostname
  useEffect(() => {
    if (host.includes("localhost")) {
      window.location.href = `/${store}`;
    }
    else {
        if (host.includes("react.") || host.includes("react-")) {
          if (store === "in" && !host.includes("react-in")) {
            window.location.href = `http://react-in.jebelz.com/in`; 
          } else if (store === "sa_en" && !host.includes("react-sa")) {
            window.location.href = `https://react-sa.jebelz.com/sa_en`; 
          } else if (store === "sa_ar" && !host.includes("react-sa")) {
              window.location.href = `https://react-sa.jebelz.com/sa_ar`; 
          } else if (store === "en" && !host.includes("react-en")) {
              window.location.href = `https://react-om.jebelz.com/en`; 
          } else if (store === "ar" && !host.includes("react-om")) {
            window.location.href = `https://react-om.jebelz.com/ar`;
          } else if (store === "ae_ar" && !host.includes("react.")) {
            window.location.href = `https://react.jebelz.com/ae_ar`;
          } else if (store === "ae_en" && !host.includes("react.")) {
            window.location.href = `https://react.jebelz.com/ae_en`;
          } else {
            window.location.href = `https://react.jebelz.com/${store}`;
          }
        } else {
          if (store === "in" && !host.includes("in.jebelz.com")) {
            window.location.href = ` https://in.jebelz.com/in`;
          } else if (store === "sa_en" && !host.includes("sa.jebelz.com")) {
            window.location.href = `https://sa.jebelz.com/sa_en`;
          } else if (store === "sa_ar" && !host.includes("sa.jebelz.com")) {
            window.location.href = `https://sa.jebelz.com/sa_ar`;
          } else if (store === "en" && !host.includes("om.jebelz.com")) {
            window.location.href = `https://om.jebelz.com/en`;
          } else if (store === "ar" && !host.includes("om.jebelz.com")) {
            window.location.href = `https://om.jebelz.com/ar`;
          } else if (store === "ae_en" && !host.includes("jebelz.com")) {
            window.location.href = `https://jebelz.com/ae_en`;
          } else if (store === "ae_ar" && !host.includes("jebelz.com")) {
            window.location.href = `https://jebelz.com/ae_ar`;
          }
          else {
            window.location.href = `https://jebelz.com/${store}`;
          }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
      }
    }, [store]);

  return <><Loading /></>;
};

export default SubdomainRedirect;
