import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import orderSuccess from "../../../NewAssets/image/order-success.png";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useContext, useEffect } from "react";
import { CountryContext } from "../MainComponent";
import Header from "../Layout/header";
import Footer from "../Layout/footer";

const OrderSuccess = () => {
  const order_no = localStorage.getItem("orderId");
  const {t} = useTranslation()
  const {store} = useContext(CountryContext) 

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <> 
    <Header />
    <div className="login-wrapper">
      <div className="order-success-wrap">
        <Grid container spacing={2} sx={{ mt: 0 }}>
          <Grid item xs={12}>
            <Box sx={{ width: 980, m: "auto" }} className= "order-success-box">
              <Card
                className="order-thank-card"
                sx={{
                  overflow: "inherit",
                  boxShadow: "none",
                  textAlign: "center",
                }}
              >
                <CardContent sx={{ width: 550, margin: "auto" }} className="order-card-content">
                  <Box sx={{ height: 64, width: 64, margin: "auto" }}>
                    <CardMedia
                      component="img"
                      image={orderSuccess}
                      alt="Order success "
                    />
                  </Box>

                  <Typography
                    variant="h4"
                    component="div"
                    sx={{ fontSize: 30, fontWeight: 700 }}
                    color="#000"
                    gutterBottom
                    className="order-success-message-typo"
                  >
                    {t('orderSucc.thanks')}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{ fontSize: 18, fontWeight: 400, lineHeight: "30px" }}
                    color="#000"
                    className="order-id-typo"
                  >
                    {t('orderSucc.orderNoText')} {order_no ? order_no: ""}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{ fontSize: 18, fontWeight: 400, lineHeight: "30px" }}
                    color="#000"
                  >
                    {t('orderSucc.emailText')}
                    {t('orderSucc.trackInfo')}
                  </Typography>
                </CardContent>
                <CardActions
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="continue-shopping-card"
                >
                  <Link to={`/${store}`}>
                    <Button
                      variant="contained"
                      disableElevation
                      sx={{
                        textTransform: "unset",
                        color: "#fff",
                        backgroundColor: "#10CDF2",
                        padding: "8px 20px",
                      }}
                    >
                      {t('orderSucc.continueShop')}
                    </Button>
                  </Link>
                </CardActions>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
    <Footer />
    </>
  );
}
export default OrderSuccess;
