import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./dashboard";
import Checkout from "./auth/checkout";
import RegSuccess from "./auth/RegSuccess";
import OrderSuccess from "./auth/OrderSuccess";
import * as queries from "../../operations/queries";
import { useQuery } from "@apollo/client";

export type ICountryContext = {
  country: string;
  setCountry: React.Dispatch<React.SetStateAction<string>>;
};

export const CountryContext = createContext({
  country: "ae_ar",
  setCountry: (country: string) => {},
  store: "",
  setStore: (store: string) => {},
  loading: true,
  setLoading: (loading: boolean) => {},
});
export const LoadingContext = createContext({
  Loading: true,
  setLoading: (loading: boolean) => {},
});

export const mainContext = createContext({
  cartData: [],
  setCartData: (data: any) => {},
  cartCount: 0,
  setCartCount: (count: any) => {},
  quest_cart_id: null,
  set_quest_cart_id: (id: any) => {},
});
function MainComponent() {
  const storePath = window.location.pathname;
  const selectedStores = storePath?.split("/")?.[1];
  const [country, setCountry] = useState("ae_ar");
  const [store, setStore] = useState("");
  const [loading, setLoading] = useState(true);
  const [cartData, setCartData] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const sessionStoreData = sessionStorage.getItem("storeAndCart");
  const loggedInToken = localStorage.getItem("loggedInCust");
  const currentsessionStore = sessionStoreData
    ? JSON.parse(sessionStoreData)
    : [];

  const filteredArray =
    currentsessionStore &&
    currentsessionStore?.filter(
      (object: any) => object.genStore === selectedStores
    );
  const [quest_cart_id, set_quest_cart_id] = useState<any>();

  const {
    loading: custlload,
    data: custQueryDataRes,
    refetch: custCartQueryDataRefetch,
  } = useQuery(queries.customerQueries.GET_CUSTOMER_CART_QUERY, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
  });
  useEffect(() => {
    custCartQueryDataRefetch();
  }, [loggedInToken, custCartQueryDataRefetch]);

  useEffect(() => {
    if (
      loggedInToken !== null &&
      loggedInToken !== undefined &&
      loggedInToken !== ""
    ) {
      set_quest_cart_id(custQueryDataRes?.customerCart?.id);
    } else if (loggedInToken === "" || currentsessionStore.length > 0) {
      const filteredArray =
        currentsessionStore &&
        currentsessionStore?.filter(
          (object: any) => object.genStore === selectedStores
        );
      if (filteredArray[0]) {
        set_quest_cart_id(filteredArray[0].sessionGenCartId);
      }
    }
  }, [loggedInToken, currentsessionStore, custQueryDataRes]);

  return (
    // <BrowserRouter basename="/jebelz-ui"></BrowserRouter>
    <CountryContext.Provider
      value={{ country, setCountry, store, setStore, loading, setLoading }}
    >
      <mainContext.Provider
        value={{
          cartData,
          setCartData,
          cartCount,
          setCartCount,
          quest_cart_id,
          set_quest_cart_id,
        }}
      >
        <BrowserRouter basename="/">
          <Routes>
            <Route path="/regsuccess" element={<RegSuccess />} />
            {/* <Route path="/registration" element={<Registration />} /> */}
            <Route path="/*" element={<Dashboard />} />
            <Route path="/:country/ordersuccess" element={<OrderSuccess />} />
            <Route path="/:country/checkout" element={<Checkout />} />
            <Route
              path="/:country/checkout/?paymentFail=true"
              element={<Checkout />}
            />
          </Routes>
        </BrowserRouter>
      </mainContext.Provider>
    </CountryContext.Provider>
  );
}

export default MainComponent;
