import * as React from 'react';
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import TablePagination from "@mui/material/TablePagination";
import { Link } from "react-router-dom";
import * as queries from "../../../operations/queries";
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      borderLeft: "1px solid rgba(224, 224, 224, 1)",
    },
  },
});

interface Column {
  id: "order" | "date" | "shipto" | "ordertotal" | "status" | "action";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: "order", label: "Order#", minWidth: 170 },
  { id: "date", label: "Date", minWidth: 100 },
  {
    id: "shipto",
    label: "Ship To",
    minWidth: 170,
    align: "right",
  },
  {
    id: "ordertotal",
    label: "Order Total",
    minWidth: 170,
    align: "right",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
    align: "right",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 170,
    align: "right",
  },
];

interface Data {
  order: string;
  date: string;
  shipto: string;
  ordertotal: string;
  status: string;
  action: string;
}

function createData(
  order: string,
  date: string,
  shipto: string,
  ordertotal: string,
  status: string,
  action: string
): Data {
  return { order, date, shipto, ordertotal, status, action };
}

function MyOrderList() {
  const { t } = useTranslation();
  const loggedInToken = localStorage.getItem("loggedInCust");
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false); // State for dialog
  const [cancelOrderReason, setCancelOrderReason] = useState('');
  const [selectedOrderId, setSelectedOrderId] = useState('');
  const [isCancelling, setIsCancelling] = useState(false); // State for tracking cancellation process

  const { data: custOrderListres, refetch } = useQuery(
    queries.customerQueries.GET_CUSTOMER_ORDER_LIST,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );

  // useEffect to update orders list after cancellation
  useEffect(() => {
    if (!isCancelling) return; // Only execute if isCancelling is true

    refetch(); // Refetch orders data after cancellation
    setIsCancelling(false); // Reset isCancelling state
  }, [isCancelling, refetch]);

  // Prepare table rows
  let rows: Data[] = [];
  let shipTo = `${custOrderListres?.customer?.firstname} ${custOrderListres?.customer?.lastname}`;

  custOrderListres?.customer?.orders?.items.forEach((data: any) => {
    const orderTotal = `${data?.total?.grand_total?.currency} ${data?.total?.grand_total?.value}`;
    const orderDate = data?.order_date.split(" ")[0];

    rows.push(createData(
      data?.number,
      orderDate,
      shipTo,
      orderTotal,
      data?.status,
      data?.number
    ));
  });

  const [cancelOrder] = useMutation(
    queries.customerQueries.CANCEL_CUSTOMER_ORDER,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
      onError: (error) => {
        console.error("Cancellation failed:", error);
      },
      onCompleted: () => {
        console.log("Order cancelled successfully");
        setOpen(false); // Close dialog on successful cancellation
        setIsCancelling(true); // Trigger useEffect to update orders list
      },
    }
  );

  const handleCancelOrder = () => {
    const variables = { order_id: selectedOrderId, reason: cancelOrderReason };
   console.log(cancelOrderReason, "cancelOrderReason");

    console.log("Cancelling order with id:", selectedOrderId);
    cancelOrder({ variables });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOpenCancelDialog = (orderId: string) => {
    setSelectedOrderId(orderId);
    setOpen(true);
  };

  const handleCloseCancelDialog = () => {
    setOpen(false);
  };

  const handleSelectOrderReason = (event: SelectChangeEvent) => {
    setCancelOrderReason(event.target.value as string);
  };

  const renderViewOrderLink = (orderNumber: string, orderStatus: string) => {
    const trackOrderLink = `tracking/${orderNumber}`;
    return (
      <>
        <Link to={trackOrderLink}>{t("buyer_dashboard.myOrderList.track_order")}</Link>
        {["Pending", "Pending Payment", "Processing", "Confirmed", "Accepted", "Pickup Requested"].includes(orderStatus) && (
          <>
            <Button variant="text" onClick={() => handleOpenCancelDialog(orderNumber)}>Cancel Order</Button>
            <Dialog
              open={open}
              onClose={handleCloseCancelDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Confirm your action?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to cancel this order?
                </DialogContentText>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Choose reason</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={cancelOrderReason}
                    onChange={handleSelectOrderReason}
                    label="Choose reason"
                  >
                    <MenuItem value="Place order by mistakenly">Place order by mistakenly</MenuItem>
                    <MenuItem value="No need it anymore">No need it anymore</MenuItem>
                    <MenuItem value="Wrong product selected">Wrong product selected</MenuItem>
                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseCancelDialog}>Cancel</Button>
                <Button onClick={handleCancelOrder} autoFocus>Agree</Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </>
    );
  };

  const viewOrderLink = (orderNumber: string) => {
    const orderLink = `order/${orderNumber}`;
    return (
      <>
        <Link to={orderLink}>{orderNumber}</Link>
      </>
    );
  };

  return (
    <>
      <Card sx={{ boxShadow: "none" }}>
        <CardContent>
          <TableContainer sx={{ maxHeight: 540 }} className="order-tbl-container">
            <Table stickyHeader aria-label="sticky table" className={classes.table}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.order}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        const orderStatus = row['status'];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === "order" ? viewOrderLink(value) :
                              column.id === "action" ? renderViewOrderLink(value, orderStatus) :
                                value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
            <div className="">
              {custOrderListres?.customer?.orders?.items?.length} {t("buyer_dashboard.myOrderList.item")}
            </div>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default MyOrderList;