import { gql } from "@apollo/client/";

export const CONTACT_US_MUTATION = gql`
  mutation ContactUs($message: String!, $email: String!, $telephone: String!, $fullname: String!) {
    contactUs(input: { message: $message, email: $email, telephone: $telephone, fullname: $fullname }) {
      success_message
    }
  }
`;

