import { gql } from "@apollo/client";

export const GET_STORECONFIG = gql`
  query getstoreconfig {
    storeConfig {
        id
        code
        website_id
        base_media_url
        header_logo_src
        locale
        base_currency_code
        default_display_currency_code
        timezone
        weight_unit
        base_url
        base_link_url
        base_static_url
        base_media_url
        secure_base_url
        secure_base_link_url
        secure_base_static_url
        secure_base_media_url
        store_name
      }
  }
`;
export const GET_TOPSLIDER = gql`
query getslider {
  getData(filter: {storeId: {eq: "1"}, apiType: {eq: "homeBanners"}}) {
      name
      image
      image_mobile
      link
  }
}
`;
export const GET_CATEGORY = gql`
query getcategory {
  getData(filter: {storeId: {eq: "2"}, apiType: {eq: "categoryBanners"}, categoryId: {eq: "1667"}}) {
      name
      image
      image_mobile
      link
  }
} 
`;

export const GET_CATEGORY_SLIDER = gql`
query getCategoryslider ($categoryId: String!) {
  getSubCategoriesData(categoryId:  $categoryId) {
    name
    image
    link
    url_key
  }
}
`
export const GET_TOPPROMOTION = gql`
query gettoppromotion {
  getData(filter: {storeId: {eq: "1"}, apiType: {eq: "homepagePromotionsTop"}}) {
      name
      image
      image_mobile
      link
  }
}
`;
export const GET_BOTOOMPROMOTION = gql`
query getbottompromotion {
  getData(filter: {storeId: {eq: "1"}, apiType: {eq: "homepagePromotionsBottom"}}) {
      name
      image
      image_mobile
      link
  }
}
`;
export const GET_BRAND = gql`
query getbrand {
  getData(filter: {storeId: {eq: "2"}, apiType: {eq: "brands"}}) {
      name
      image
      image_mobile
      link
  }    
}
`;

export const GET_HOME_META = gql`
query getHomeMeta {
  cmsPage(identifier: "home") {
    title
    url_key
    meta_title
    meta_keywords
    meta_description
  }   
}
`;

export const GET_OUR_CLIENTS = gql`
query {
  getData(filter: {apiType: {eq: "ourClients"}}) {
      name
      image
      image_mobile
      link
  }
}
`;