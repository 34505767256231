import './App.css';
import { ThemeProvider,CssBaseline } from '@mui/material';
import theme from './theme';
import MainComponent from './container/buyer/MainComponent';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// const theme = createTheme({
// ,});
function App() {
  return (
    <ThemeProvider theme={theme}>
       <CssBaseline />
      <MainComponent/>
      <ToastContainer/>
   </ThemeProvider>
  );
}

export default App;
