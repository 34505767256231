import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, TextField, Typography } from '@mui/material';
import { Grid } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import Rating from '@mui/material/Rating';

interface AddReviewModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (reviewData: any) => void;
    detailsData: any;
    productName: string;
    productImage: any;
    ratingMetadata: { value: string; value_id: string }[];
    avgRating: number;
    ratingMetaDataId: string;
}

function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

const labels: { [index: string]: string } = {
    1: 'Useless+',
    2: 'Poor+',
    3: 'Ok+',
    4: 'Good+',
    5: 'Excellent+',
};

interface ReviewDataInterface {
    description: string;
    title: string;
    name: string;
}

const initialFormValues: ReviewDataInterface = {
    description: '',
    title: '',
    name: ''
};

const AddReviewModal: React.FC<AddReviewModalProps> = ({ open, onClose, onSubmit, detailsData, productName, productImage, ratingMetadata, avgRating, ratingMetaDataId }) => {

    const [value, setValue] = useState<number | null>(3);
    const [hover, setHover] = useState(-1);
    const [ratingValue, setRatingValue] = useState<string>("")
    const [formData, setFormData] = useState<ReviewDataInterface>(initialFormValues);
    const handleCLose = () => {
        onClose && onClose()
    }

    const handleSubmit = async () => {

        const reviewData = {
            sku: detailsData?.sku,
            nickname: formData.name,
            summary: formData.description,
            text: formData.title,
            ratings: [{
                id: ratingMetaDataId,
                value_id: ratingValue
            }]
        };
        console.log("Submitted Data from addReview", reviewData);
        setFormData(initialFormValues)
        onSubmit(reviewData)
    };
    useEffect(() => {
        if (value !== null) {
            console.log("review star rating", value);

            const selectedRatingMetadata = ratingMetadata.find(metadata => metadata.value === String(value));
            if (selectedRatingMetadata) {
                console.log("Selected rating value_id:", selectedRatingMetadata.value_id);
                setRatingValue(selectedRatingMetadata.value_id)
            }
        }
    }, [value, ratingMetadata]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const ratingClassName = avgRating >= 3 ? "green" : (avgRating >= 2 ? "orange" : "red");

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleCLose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='reviews-modal'
            >
                <DialogTitle id="alert-dialog-title" className='review-title'>
                    {"Rate this product"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Box sx={{ borderBottom: '1px solid #EEEBEB', marginBottom: '20px' }}>
                            <Box sx={{
                                display: 'flex',
                                '@media (max-width:320px)': {
                                    flexWrap: 'wrap',
                                }
                            }}>
                                <div className='product-image'>
                                    <img src={productImage} alt={productName} />
                                </div>
                                <div className='product-details'>
                                    <Typography variant='h5'>{productName}</Typography>
                                    <div className="rating-star-tag">
                                        <span className={`user-rating-star ${ratingClassName}`}>
                                            {detailsData?.reviews?.items.length ? avgRating : 0}
                                            <StarIcon />
                                        </span>
                                        <span className="rating-tag">({detailsData.rating_summary})</span>
                                    </div>
                                </div>
                            </Box>
                            <Box
                                sx={{
                                    width: 200,
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: '20px',
                                    marginBottom: '30px',
                                    '@media (max-width:600px)': {
                                        marginBottom: '15px'
                                    },
                                }}
                            >
                                <Rating
                                    name="hover-feedback"
                                    value={value}
                                    precision={1}
                                    getLabelText={getLabelText}
                                    onChange={(event, newValue) => {
                                        setValue(newValue);
                                    }}
                                    onChangeActive={(event, newHover) => {
                                        setHover(newHover);
                                    }}
                                    style={{ color: '#439840' }}
                                    emptyIcon={<StarIcon style={{ color: '#DDDADA' }} fontSize="inherit" />}
                                />
                                {value !== null && (
                                    <Box sx={{ ml: 2, color: '#439840', fontSize: '14px', fontWeight: '600', fontFamily: 'poppins', lineHeight: '21px' }}>
                                        {labels[hover !== -1 ? hover : value]}
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} className="form-title">
                                    <Typography variant='h5'>Review this product</Typography>
                                </Grid>
                                <Grid item xs={12} className="input-field-wrap">
                                    <label>Description</label>
                                    <TextField
                                        placeholder="Review Description"
                                        required
                                        fullWidth
                                        name="description"
                                        value={formData.description}
                                        onChange={handleChange}
                                        autoComplete="off"
                                        multiline
                                        rows={5}
                                    />
                                </Grid>
                                <Grid item xs={12} className="input-field-wrap">
                                    <label>Title</label>
                                    <TextField
                                        placeholder="Review Titile"
                                        required
                                        fullWidth
                                        id="title"
                                        name="title"
                                        autoComplete="off"
                                        value={formData.title}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} className="input-field-wrap">
                                    <label>Name</label>
                                    <TextField
                                        placeholder="name"
                                        required
                                        fullWidth
                                        id="name"
                                        name="name"
                                        autoComplete="off"
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item lg={3} md={4} xs={12} className="input-field-wrap">
                                    <Button
                                        variant="contained"
                                        sx={{ backgroundColor: "#00B4D7", width: "100%", boxShadow: 'unset' }}
                                        onClick={handleSubmit}
                                        disabled={!formData.description || !formData.title || !formData.name}
                                    >
                                        <Typography color="white" style={{ textTransform: "capitalize" }}>
                                            Submit
                                        </Typography>
                                    </Button>
                                </Grid>

                            </Grid>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

export default AddReviewModal