// ModalComponent.js
import React, { useContext, useState } from 'react';
import { Modal, Box, Typography, Backdrop, IconButton, InputLabel } from '@mui/material';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import * as mutations from "../../../operations/mutations";
import { saveAs } from 'file-saver';
import { CountryContext, mainContext } from '../MainComponent';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const QuotationModal = (props: any) => {
  const { t } = useTranslation();
  const { quest_cart_id } = useContext(mainContext)
  const { store } = useContext(CountryContext)
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [vatNo, setVatNo] = useState('');
  const [fName, setFName] = useState('')

  const validateForm = async () => {
  
    if (fName === "") {
      toast.error("First name is required");
      return false;
    } else if (email === "") {
      toast.error("Email id is Required");
      return false;
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
    ) {
      toast.error("Invalid Email");
      return false;
    } else if (phoneNumber === "") {
      toast.error("Phone number is Required");
      return false;
    } else if (!/^\d+$/.test(phoneNumber)) {
      toast.error("Phone number must contain only numbers");
      return false;
    } 
    return true;

  };
  const handleDownload = async (event: any) => {
    console.log("form submit")
    event.preventDefault();
    props.setOpenQuotation(false);
    const isValid = await validateForm();

    if (isValid) {
      try {
        setDownloadLoading(true);
        const response = await fetch('https://access.jebelz.com/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/pdf',
            store: store
          },
          body: JSON.stringify({
            query: mutations.addToCartMutation.DOWNLOAD_QUOTATION,
            variables: {
              cart_id: quest_cart_id,
              email: event?.target?.email?.value,
              full_name: event?.target?.full_name?.value,
              phone_number: event?.target?.phone_number?.value,
              vat_number: event?.target?.vat_number?.value,
            },
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch PDF');
        }

        const blob = await response.blob();
        saveAs(blob, 'Report.pdf');
      } catch (error) {
        console.error('Error downloading PDF:', error);
      } finally {
        setDownloadLoading(false);
      }
    }
  };
  return (

    <Modal
      open={props.open}
      onClose={props.close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropComponent={Backdrop}
      BackdropProps={{ onClick: props.close }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '1px solid #ebebeb',
          borderRadius: '15px',
          boxShadow: 24,
          p: 0,
        }}
      >
        <Card sx={{ borderRadius: '15px' }}>
          <div
            style={{
              borderBottom: '1px solid #ebebeb',
              marginBottom: '10px',
              padding: '10px',
              paddingTop: '0px'
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
              <IconButton color="inherit" onClick={props.close} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Box>

            <Typography variant="h6" component="div" sx={{ fontSize: '16px', textAlign: 'center' }}>
            {t("quotation-download.quotation-title")}
            </Typography>

          </div>
          <Box component="form" noValidate autoComplete="off" onSubmit={handleDownload} sx={{ px: 2, py: 1 }} className='quotation-download-from'>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <InputLabel
                  htmlFor="input-field"
                  sx={{ mb: 1 }}
                  required
                >
                  {t("quotation-download.full_name")}
                </InputLabel>
                <TextField
                  className='custom-textfield'
                  autoComplete="off"
                  name="full_name"
                  autoFocus={true}
                  required
                  fullWidth
                  id="full_name"
                  placeholder=''
                  onChange={(e) => setFName(e.target.value)}
                  value={fName}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel
                  htmlFor="input-field"
                  sx={{ mb: 1 }}
                  required
                >
                  {t("quotation-download.email")}
                </InputLabel>
                <TextField
                  className='custom-textfield'
                  autoComplete="off"
                  name="email"
                  required
                  fullWidth
                  id="email"
                  placeholder=''
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel
                  htmlFor="input-field"
                  sx={{ mb: 1 }}
                  required
                >
                  {t("quotation-download.phone_no")}
                </InputLabel>
                <TextField
                  className='custom-textfield'
                  autoComplete="off"
                  name="phone_number"
                  required
                  fullWidth
                  id="phone_number"
                  placeholder=''
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  value={phoneNumber}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel
                  htmlFor="input-field"
                  sx={{ mb: 1 }}

                >
                  {t("quotation-download.vat_no")}
                </InputLabel>
                <TextField
                  className='custom-textfield'
                  autoComplete="off"
                  name="vat_number"
                  fullWidth
                  id="vat_number"
                  placeholder=''
                  onChange={(e) => setVatNo(e.target.value)}
                  value={vatNo}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                className='download-quotation-btn'
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 2,
                    mb: 2,
                    textTransform: "capitalize",
                    color: "#fff",
                    fontSize: "18px",
                    fontWeight: "500",
                    padding: "6px 34px",
                    width: '100%',
                  }}
                  type="submit"
                >
                  {downloadLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    t("quotation-download.download")
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>

        </Card>
      </Box>
    </Modal>
  );
};

export default QuotationModal;
