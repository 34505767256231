import { gql } from "@apollo/client";

export const LOGIN_MUTATION= gql`
mutation login($email: String!, $password: String!) {
    generateCustomerToken(
        email: $email
        password: $password
      ) {
        token
      }
  }
`;
export const CREATECUSTOMER_MUTATION= gql`
mutation createCustomer($firstname: String!, $lastname: String!, $email: String!, $password: String!, $is_subscribed : Boolean!) {
  createCustomer(
    input: {
      firstname: $firstname
      lastname: $lastname
      email:  $email
      password: $password
      is_subscribed: $is_subscribed
    }
  ) {
    customer {
      firstname
      lastname
      email
      is_subscribed
    }
  }
}
`;
export const CREATE_EMPTY_CART = gql`
  mutation createEmptyCart{
    createEmptyCart
  }
`;

export const ASSIGN_CUST_GUEST_CART_NEW = gql`
mutation assignCustToCart($cart_id: String!){
  assignCustomerToGuestCart(
    cart_id: $cart_id
  ) {
    items {
      quantity
      product {
        sku
      }
    }
  }
}
`;
// CREATE_CUSTOMER_ADDRESS_MUTATION.graphql
// export const CREATE_CUSTOMER_ADDRESS_MUTATION = gql`
// mutation CreateCustomerAddress($input: CustomerAddressInput!) {
//   createCustomerAddress(input: $input) {
//     id
//     region {
//       region
//       region_code
//     }
//     country_code
//     street
//     telephone
//     postcode
//     city
//     default_shipping
//     default_billing
//   }
// }
// `;