import React, { useState, useContext, useEffect } from "react";
import Layout from "./layout";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./Home/home";
import DetailsPage from "./Innerpages/DeatilsPage";
import { CountryContext } from "./MainComponent";
import DomesticMachine from "./Innerpages/Domestic-machine";
import Login from "./auth/login";
import BuyDashboard from "./Buyer-Dashboard/BuyDashboard";
import TelsTest from "../../components/telr/telrTest";
import OrderSuccess from "./auth/OrderSuccess";
import { useQuery } from "@apollo/client";
import * as queries from "../../operations/queries";
import Payment from "../../components/CMSPages/payment";
import axios from "axios";
import i18n from "../../i18n";
import SubDomain from "./SubDomain";
import Register from "./Innerpages/Registration";
import ForgotPwd from "./Innerpages/forgotPwd";
import SearchResult from "./Innerpages/SearchResult/SearchResult";
import Loader from "./Layout/loader";
import ServicePage from "../../components/servicePage/servicePage";
import BottomNavbarAllcategories from "./Layout/BottomNavbarAllcategories";
import Blog from "./Layout/Blog";
import BrandPage from "./Innerpages/BrandPage";
import BrandList from "./Innerpages/BrandList";
import BlogDetail from "./Layout/BlogDetail";

function Dashboard() {
  const location = useLocation();
  useEffect(() => {
    const headElement = document.querySelector('head');
    const canonicalLinkTag = headElement?.querySelector('link[rel="canonical"]');
    if(canonicalLinkTag) {
      canonicalLinkTag?.setAttribute('href', window.location.href);
    }
    else {
      document.getElementsByTagName('link')
      var link = document.createElement('link');
      link.rel = 'canonical';
      link.href = window.location.href;
      document.head.appendChild(link);
    }
  },[location])

  /*useEffect(() => {
    console.log("useLocation 1: ",location)
    console.log("useLocation 2: location ",window.location)
    console.log("useLocation 3: title ",document.head.title)
  },[document.head.title])*/
  const { setCountry, store, setStore } =
    useContext(CountryContext);
  // const [store, setStore] = useState("");
  const [onLoad, setOnLoad] = useState(true);
  const [cartId, setCartId] = useState("");
  const [currentStore, setCurrentStore] = useState("");
  const handleCountryChange = (store: any, val: any, load: boolean) => {
    // setStore(store);
    setCountry(val);
    setOnLoad(load);
  };
  const customHeader = {
    store: store,
    Connection: "keep-alive",
  };
  const { data: storeRes } = useQuery(queries.headerQueries.GET_STORES, {
    context: {
      headers: customHeader,
    },
  });
  const availableStore = storeRes?.getStores;
  const handleCartId = (cartId: any) => {
    setCartId(cartId);
    console.log("dashboard",cartId)
  };

  let storeLang: string = "";
  let country: string = "";

  useEffect(() => {
    const path = window.location.pathname;
    const selecteStore = path?.split("/");
    if (selecteStore[1] === "in") {
      storeLang = "in";
      country = "in";
    } else if (selecteStore[1] === "ae_en") {
      storeLang = "ae_en";
      country = "ae_ar";
    } else if (selecteStore[1] === "ae_ar") {
      storeLang = "ae_ar";
      country = "ae_ar";
    } else if (selecteStore[1] === "sa_ar") {
      storeLang = "sa_ar";
      country = "sa_en";
    } else if (selecteStore[1] === "sa_en") {
      storeLang = "sa_en";
      country = "sa_en";
    } else if (selecteStore[1] === "ar") {
      storeLang = "ar";
      country = "en";
    } else if (selecteStore[1] === "en") {
      storeLang = "en";
      country = "en";
    } else {
      axios
        .get("https://ipapi.co/json/")
        .then((response) => {
          let data = response.data;
          if (
            data?.country_code === "UA" ||
            data?.country_code === "ARE" ||
            data?.country_code === "AE"
          ) {
            storeLang = "ae_en";
            country = "ae_ar";
          } else if (
            data?.country_code === "SD" ||
            data?.country_code === "SAU" ||
            data?.country_code === "SA"
          ) {
            storeLang = "sa_ar";
            country = "sa_en";
          } else if (
            data?.country_code === "OM" ||
            data?.country_code === "OMN" ||
            data?.country_code === "OM"
          ) {
            storeLang = "ar";
            country = "en";
          } else if (
            data?.country_code === "IN" ||
            data?.country_code === "IND"
          ) {
            storeLang = "in";
            country = "in";
          } else {
            storeLang = "ae_en";
            country = "ae_ar";
          }
          // storeLang = "in";
          // country = "in";
          setStore(storeLang);
          setCountry(country);
          setCurrentStore(storeLang);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setStore(storeLang);
    setCountry(country);
    setCurrentStore(storeLang);
  }, []);

  useEffect(() => {
    if (currentStore !== store) {
      const host = window.location.hostname;
      if (host.includes("react-")) {
        if (store === "in" && !host.includes("react-in")) {
          window.location.href = `http://react-in.jebelz.com/${store}`;
          // window.location.href = `/${store}`;
        } else if (
          (store === "sa_ar" && !host.includes("react-sa")) ||
          (store === "sa_en" && host.includes("react-sa"))
        ) {
          window.location.href = `https://react-sa.jebelz.com/${store}`;
        } else if (
          (store === "ar" && !host.includes("react-om")) ||
          (store === "en" && host.includes("react-om"))
        ) {
          window.location.href = `https://react-om.jebelz.com/${store}`;
        } else {
          window.location.href = `https://react.jebelz.com/${store}`;
        }
      } else {
        if (store === "in" && !host.includes("in")) {
          window.location.href = ` https://in.jebelz.com/${store}`;

          // window.location.href = `/${store}`;
        } else if (
          (store === "sa_ar" && !host.includes("sa")) ||
          (store === "sa_en" && host.includes("sa"))
        ) {
          window.location.href = `https://sa.jebelz.com/${store}`;
        } else if (
          (store === "ar" && !host.includes("om.")) ||
          (store === "en" && host.includes("om."))
        ) {
          window.location.href = `https://om.jebelz.com/${store}`;
        } else {
          window.location.href = `https://jebelz.com/${store}`;
        }
      }
    }
  }, [store, currentStore]);

  useEffect(() => {
    if (store && store !== "" && store !== undefined) {
      if (store === "en" || store.split("_")[1] === "en" || store === "in") {
        let beforeVal: any;
        if (store.indexOf("_") !== -1) {
          beforeVal = store.split("_")[0] + "_ar";
        } else {
          beforeVal = "en";
        }
        i18n.changeLanguage("en");
        document.documentElement.setAttribute("lang", "en");
        document.body.classList.add("en");
        document.body.classList.remove("ar");
      } else {
        let beforeVal: any;
        if (store.indexOf("_") !== -1) {
          beforeVal = store.split("_")[0] + "_en";
        } else {
          beforeVal = "ar";
        }
        i18n.changeLanguage("ar");
        document.documentElement.setAttribute("lang", "ar");
        document.body.classList.add("ar");
        document.body.classList.remove("en");
      }
    }
  }, [store]);

  return (
    <Layout countryCall={handleCountryChange} isCartId={cartId}>
      {store ? (
        <Routes>
          <>
              <Route path="/" element={<SubDomain />} />
              <Route
                path={`/:country`}
                element={
                  <Home isCountry={store} store={store} onLoad={onLoad} />
                }
              />
              <Route
                path="/:country/:urlKey"
                element={<DetailsPage getCartId={handleCartId} />}
              />
              <Route
                path={`/:country/category/:categoryName`}
                element={<DomesticMachine />}
              />
              <Route
                path={`/:country/category/brands/:categoryName`}
                element={<DomesticMachine />}
              />
              <Route
                path={`/:country/category/:categoryName/:subcategory`}
                element={<DomesticMachine />}
              />
              <Route
                path={`/:country/search/:searchParam`}
                element={<SearchResult />}
              />
              <Route path="/:country/login" element={<Login />} />
              <Route path="/:country/registration" element={<Register />} />
              <Route path="/:country/forgotpassword" element={<ForgotPwd />} />
              <Route
                path="/:country/buyerdashboard/*"
                element={<BuyDashboard />}
              />
              <Route path="/:country/telrPayment" element={<TelsTest />} />
              <Route path="/:country/ordersuccess" element={<OrderSuccess />} />
              <Route path="/:country/cms/:payments" element={<Payment />} />
              <Route path="/:country/cms/service" element={<ServicePage />} />
              <Route path="/:country/all-categories" element={<BottomNavbarAllcategories />} />
              <Route path="/:country/blog.html" element={<Blog />} />
              <Route path="/:country/blog/:blogDetail" element={<BlogDetail />} />
              <Route path="/:country/brands.html" element={<BrandList />} />
              <Route path="/:country/brand/:brandname" element={<BrandPage />} />
          </>
        </Routes>
      ) : (
        <Loader />
      )}
    </Layout>
  );
}
export default Dashboard;
