import { useEffect, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery, gql } from "@apollo/client";
import { CountryContext } from "../../container/buyer/MainComponent";
import ReactStars from "react-rating-star-with-type";
import Skeleton from '@mui/material/Skeleton';
let query: any = "";

let queryData: any = {
  "Top Sellers": 1780,
  "لشراء تسجيل ": 1780,
  Brewers: 1654,
  الشرا: 1654,
  "Coffee Corner": 1653,
  " تسجيل": 1653,
  "Coffee's Filter ": 1681,
  "فلتر القهوة": 1681,
  "New Arrivals": 1682,
  " الشرا": 1682,
  "Barista's Pick": 1744,
  "سلة الشرا": 1744,
  Accessories: 1605,
  الدخول: 1605,
  "Cleaning Products": 105,
  "الدخول الشراء": 105,
  "Staff Favorites": 1593,
  تسجيل: 1593,
  "Office Pick's": 1676,
  "اختيارات المكتب": 1676,
};
// const top_seller_responsive: any = {
//   superLargeDesktop: {
//     breakpoint: { max: 4000, min: 1536 },
//     items: 6,
//   },
//   desktop: {
//     breakpoint: { max: 1536, min: 1200 },
//     items: 4,
//     slidesToSlide: 1,
//   },
//   laptop: {
//     breakpoint: { max: 1200, min: 900 },
//     items: 3,
//     slidesToSlide: 1,
//   },
//   tablet: {
//     breakpoint: { max: 900, min: 600 },
//     items: 2,
//     slidesToSlide: 2,
//   },
//   mobile: {
//     breakpoint: { max: 600, min: 0 },
//     items: 2,
//     slidesToSlide: 1,
//   },
// };
const top_seller_responsive: any = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1200 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 1200, min: 900 },
    items: 4,
    slidesToSlide: 1,
  },
  laptop: {
    breakpoint: { max: 900, min: 600 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 600, min: 0 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 2,
    slidesToSlide: 1,
  },
};

interface carouselProps {
  title: any;
  iscountry: any;
  isLoadingCarousel: (data: boolean) => void
}

function CarouselSlider(props: carouselProps) {
  query = gql`
    query get_listData {
        products(filter: {category_id: {eq: \"${
          queryData[props.title]
        }\"}}, pageSize: 25) {
          url_key
          category_name
            items {
                name
                delivery_estimation
                top_badge_left
                top_badge_left_title
                top_badge_right
                top_badge_right_title
                sku
                url_key
                stock_status
                short_description {
                    html
                }
                attributes {
                    label
                    value
                }
                review_count
              rating_summary
              reviews {
                  items {
                      average_rating
                      summary
                      text
                      created_at
                      nickname
                  }
              }
              small_image {
                  disabled
                  label
                  position
                  url
                  }
                description {
                    html
                }
                ... on ConfigurableProduct {
                      media_gallery {
                          url
                          label
                          position
                      }
                      variants {
                          attributes {
                              uid
                              label
                              code
                          }
                          product {
                              small_image 
                              {
                                  label
                                  url
                              }
                          }
                      }
                      configurable_options{
                          values {
                              label
                              swatch_data{
                                  value
                              }
                          }
                      }
          
                }
                price_range {
                  minimum_price {
                    regular_price {
                      value
                      currency
                    }
                    final_price {
                      value
                      currency
                    }
                    discount {
                      amount_off
                      percent_off
                    }
                  }
                }
                image {
                  url
                  small_url
                  label
                }
                small_image{
                    url
                    label
                }
                media_gallery {
                    url
                    thumbnail_url
                    label
                    ... on ProductVideo {
                        video_content {
                            media_type
                            video_provider
                            video_url
                            video_title
                            video_description
                            video_metadata
                        }
                    }
                }
                related_products {
                  uid
                  name
                  small_image{
                      url
                      label
                  }
                  price_range {
                      minimum_price {
                          regular_price {
                              value
                              currency
                          }
                      }
                  }
                }
                upsell_products {
                  uid
                  name
                }
                crosssell_products {
                  uid
                  name
                }
              }
        }
      }
      `;

  const { country, store } = useContext(CountryContext);
  const [selectedStore, setSelectedStore] = useState("");
  const skeletonArr = Array.from({ length: 10 });

  let productData: any;
  const customeHeader = {
    store: store,
    Connection: "keep-alive",
  };

  const {
    loading: productloading,
    data: productdata,
    refetch,
  } = useQuery(query, {
    context: {
      headers: customeHeader,
    },
  });

  console.log("----- carousel productData ---", productdata )

  productData = productdata?.products?.items;
  const { t } = useTranslation();
  useEffect(() => {
    if (store && store !== selectedStore) {
      setSelectedStore(store);
      refetch();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, country, props?.iscountry, refetch]);

useEffect(() => {
  if (productloading) {
    props.isLoadingCarousel(true);
  } else {
    props.isLoadingCarousel(false);
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[productloading])

  return (
    <>
      {productData === undefined ? (
        <Box sx={{ pt: { lg: 5, md: 4, xs: 3 } }}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Card
                sx={{ maxWidth: "100%", boxShadow: 0 }}
                className="top-seller-grid jeb-cust-slide">
                <CardContent sx={{ backgroundColor: "#fafafa" }}>
                  <Carousel
                    className="top-seller-grid-slider"
                    responsive={top_seller_responsive}
                    swipeable={true}
                    autoPlay={false}
                    centerMode={false}
                    arrows={true}
                    infinite={true}
                    ssr={true}
                    showDots={false}
                    slidesToSlide={1}
                    deviceType={""}
                  >
                    {skeletonArr?.map((ele) => {
                      return (
                        <Card
                          sx={{
                            maxWidth: "100%",
                            boxShadow: 0,
                            border: "1px solid #e1e1e1",
                            margin: {
                              xs: "0px 5px",
                              md: "0px 10px",
                              lg: "0px 12px",
                              xl: "0px 15px",
                            },
                          }}
                        >
                          <div key={productData?.sku}>
                            <div className="slider-img-box">
                              <Skeleton variant="rectangular" sx={{
                                width: '100%',
                                height: '100%',
                              }} />
                            </div>
                          </div>
                        </Card>
                      )
                    })}
                  </Carousel>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      ) : productData && productData.length > 0 ? (
        <Box sx={{ pt: { lg: 5, md: 4, xs: 3 } }}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Card
                sx={{ maxWidth: "100%", boxShadow: 0 }}
                className="top-seller-grid jeb-cust-slide"
              >
                <div className="section-title">
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="span"
                    color="text.dark"
                  >
                    {productdata?.products?.category_name}
                  </Typography>
                  <Link
                    to={`/${store}/category/${productdata?.products?.url_key}.html`}
                  >
                    <Button className="view-all-brand-btn" sx={{ mr: 2 }} variant="outlined">
                      {t("main.view-all-prod")}
                    </Button>
                  </Link>
                </div>
                <CardContent sx={{ backgroundColor: "#fafafa" }}>
                  <Carousel
                    className="top-seller-grid-slider"
                    responsive={top_seller_responsive}
                    swipeable={true}
                    autoPlay={false}
                    centerMode={false}
                    arrows={true}
                    infinite={true}
                    ssr={true}
                    showDots={false}
                    slidesToSlide={1}
                    deviceType={""}
                  >
                    {productData.map((productdata: any, index: any) => {
                      return (
                        <Card
                          sx={{
                            maxWidth: "100%",
                            boxShadow: 0,
                            border: "1px solid #e1e1e1",
                            margin: {
                              xs: "0px 5px",
                              md: "0px 10px",
                              lg: "0px 12px",
                              xl: "0px 15px",
                            },
                          }}
                        >
                          <div key={productData?.sku}>
                            <Link to={`/${store}/${productdata?.url_key}.html`}>
                              <div className="slider-img-box">
                                <CardMedia
                                  component="img"
                                  alt={productdata?.name}
                                  height="100%"
                                  sx={{ m: "auto", width: "auto" }}
                                  image={productdata?.image?.small_url}
                                />
                              </div>
                              <CardContent sx={{ textAlign: "start" }}>
                                <Typography
                                  gutterBottom
                                  variant="h6"
                                  component="div"
                                  color="text.dark"
                                  className="top-sell-title"
                                >
                                  {productdata?.name}
                                </Typography>
                                {productdata?.price_range?.minimum_price?.discount
                                  ?.amount_off > 0 && (
                                    <Typography
                                      gutterBottom
                                      variant="h6"
                                      color="text.secondary"
                                      component="del"
                                      className="top-actual_amount"
                                    >
                                      {
                                        productdata?.price_range?.minimum_price
                                          ?.regular_price?.currency
                                      }
                                      {
                                        productdata?.price_range?.minimum_price
                                          ?.regular_price?.value
                                      }
                                    </Typography>
                                  )}
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  color="text.dark"
                                  className="top-sell-amount"
                                >
                                  {
                                    productdata?.price_range?.minimum_price
                                      ?.final_price?.currency
                                  }&nbsp;
                                  {
                                    productdata?.price_range?.minimum_price
                                      ?.final_price?.value
                                  }
                                </Typography>
                                {productdata?.configurable_options && (
                                  <div className="color-switch-list">
                                    {productdata?.configurable_options[0]?.values
                                      ?.length > 2
                                      ? productdata?.configurable_options[0]?.values?.map(
                                        // eslint-disable-next-line array-callback-return
                                        (item: any, index: any) => {
                                          const restCount =
                                            productdata?.configurable_options[0]
                                              ?.values?.length - 4;
                                          // const a = productdata[index].colors;
                                          if (index === 4) {
                                            return (
                                              <Button size="small">
                                                <div className="color-switch">
                                                  +{restCount}
                                                </div>
                                              </Button>
                                            );
                                          } else if (index < 4) {
                                            return (
                                              <Button size="small">
                                                <div
                                                  className="color-switch"
                                                  style={{
                                                    backgroundColor:
                                                      item?.swatch_data?.value,
                                                  }}
                                                ></div>
                                              </Button>
                                            );
                                          }
                                        }
                                      )
                                      : productdata?.configurable_options[0]?.values.map(
                                        (colors: any) => {
                                          return (
                                            <Button size="small">
                                              <div
                                                className="color-switch"
                                                style={{
                                                  backgroundColor:
                                                    colors?.swatch_data?.value,
                                                }}
                                              ></div>
                                            </Button>
                                          );
                                        }
                                      )}
                                  </div>
                                )}
                                <div className="stars-block carouselSlide" >
                                  {productdata?.rating_summary > 0 && (
                                    <ReactStars
                                      size={18}
                                      isHalf={true}
                                      activeColor="#DD8C27"
                                      value={productdata?.rating_summary}
                                      activeColors={[
                                        "#DD8C27",
                                        "#DD8C27",
                                        "#DD8C27",
                                        "#DD8C27",
                                        "#DD8C27",
                                      ]}
                                    />
                                  )}
                                  {productdata?.review_count > 0 && (
                                    <div>
                                      <span>
                                        {productdata?.review_count} Review
                                      </span>
                                    </div>
                                  )}
                                </div>
                                <Typography
                                  gutterBottom
                                  variant="h6"
                                  color="text.dark"
                                  className="top-vat"
                                >
                                  {country === "in"
                                    ? t("main.inclusive_of_gst")
                                    : t("main.inclusive_of_vat")}
                                </Typography>
                              </CardContent>
                            </Link>
                          </div>
                        </Card>
                      );
                    })
                    }
                  </Carousel>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      ) : ""}
    </>
  );
}

export default CarouselSlider;
