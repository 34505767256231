
import { gql } from "@apollo/client";

export const APPLY_COUPON_MUTATION = gql`
  mutation ApplyCouponToCart($cartId: String!, $couponCode: String!) {
    applyCouponToCart(
      input: {
        cart_id: $cartId,
        coupon_code: $couponCode
      }
    ) {
      cart {
        items {
          product {
            name
          }
          quantity
        }
        applied_coupons {
          code
        }
        prices {
          grand_total {
            value
            currency
          }
        }
      }
    }
  }
`;

export const REMOVE_COUPON_MUTATION = gql`
  mutation RemoveCouponFromCart($cartId: String!) {
    removeCouponFromCart(
      input: {
        cart_id: $cartId
      }
    ) {
      cart {
        items {
          product {
            name
          }
          quantity
        }
        applied_coupons {
          code
        }
        prices {
          grand_total {
            value
            currency
          }
        }
      }
    }
  }
`;
