import "./loadingContext.css"

const GlobalLoader = (props:any) => {
  return (
    <>
      {props.type === "isSmallLoader" ? (
        <div>
          <img src={require('../../../NewAssets/image/small-loader-img.gif')} alt='Loader' />
        </div>
      ) : (
        <div className='overlay' style={{ height: props.type === "Home" ? "100vh" : "8vh" }}>
          <img src={require('../../../NewAssets/image/loader-img.gif')} alt='Loader' className="custom_loader" />
        </div>
      )}
    </>
  );
};

export default GlobalLoader;