import "./servicePage.css"
import bodyImg from "./assets/body-img.png"
import coffeeMachine from "./assets/coffee-machines.png"
import grinders from "./assets/grinders.png"
import waterFilteration from "./assets/water-filteration.png"
import iceMaker from "./assets/ice-maker.png"
import autoMachines from "./assets/auto-machines.png"
import manualBrewer from "./assets/manual-brewer.png"
/* import backArrow from "./assets/backward-arrow.png" */
import ascasso from "./assets/ascasso.png"
import baratza from "./assets/baratza.png"
import hario from "./assets/hario.png"
import bunn from "./assets/BUNN.png"
import nespresso from "./assets/Nespresso.png"
import simonelli from "./assets/simonelli.png"
/* import forwardArrow from "./assets/forward-arrow.png" */
import exprtAdvice from "./assets/expert-advice.png"
import amc from "./assets/amc.png"
import healthCheck from "./assets/health-check.png"
import installation from "./assets/installation.png"
import { Button, Container, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import ContactModal from "../servicePage-modal/contactModal"

const ServicePage = () => {
    const [showContactModal, setShowContactModal] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const handleContactModalOpen = () => {
        setShowContactModal(true);
    }
    const handleContactModalClose = () => {
        setShowContactModal(false);
    }
    const handleFormSubmit = () => {
        console.log("Form submitted");
        handleContactModalClose();
    }
    return (
        <>
            <div className="main-body service-wrapper">
                {/* <img className="main-body-img" src={navbar} alt='navbar' /> */}
                <div className="second-img-container">
                    <div className="overlay">
                        <img className="main-body-img" src={bodyImg} alt='body-img' />
                        <div className="overlay-text">
                            <h1 className="body-img-upper-text">
                                Our dedicated team is all about <br /> “Innovation in Brewing.”
                            </h1>
                            <p className="body-img-lower-text">
                                With the UAE's largest and most experienced team of coffee equipment engineers, we handle any
                                challenge with expertise and precision.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="main-container">
                    <div className="service-one-block">
                        <Container>
                            <div className="service-1">
                                <div className="Title-service1">
                                    Our Service Range
                                </div>
                                <div className="description-service1">
                                    We take service standards seriously, ensuring the highest customer satisfaction. Our comprehensive
                                    inventory
                                    of micro to macro spare parts enables swift and efficient repairs for various coffee equipment,
                                    including
                                    coffee machines, grinders, water filtration, kettles, scales, and more.
                                </div>
                                <div className="grid-container">
                                    <div className="grid-item">
                                        <div className="product-grid-img">
                                            <img src={coffeeMachine} alt="Coffee machines" />
                                        </div>
                                        <p>Coffee machines</p>
                                    </div>
                                    <div className="grid-item">
                                        <div className="product-grid-img">
                                            <img src={grinders} alt="Grinders" />
                                        </div>
                                        <p>Grinders</p>
                                    </div>
                                    <div className="grid-item">
                                        <div className="product-grid-img">
                                            <img src={waterFilteration} alt="Water Filteration" />
                                        </div>
                                        <p>Water Filteration</p>
                                    </div>
                                    <div className="grid-item">
                                        <div className="product-grid-img">
                                            <img src={iceMaker} alt="Ice Maker" />
                                        </div>
                                        <p>Ice Maker</p>
                                    </div>
                                    <div className="grid-item">
                                        <div className="product-grid-img">
                                            <img src={autoMachines} alt="Automatic Machines" />
                                        </div>
                                        <p>Automatic Machines</p>
                                    </div>
                                    <div className="grid-item">
                                        <div className="product-grid-img">
                                            <img src={manualBrewer} alt="Manual Brewer and More" />
                                        </div>
                                        <p>Manual Brewer and More</p>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>

                    <div className="service-two-block">
                        <Container>
                            <div className="service-2">
                                <div className="Title-service2">
                                    Our Service Range
                                </div>
                                <div className="description-service2 ">
                                    We take service standards seriously, ensuring the highest customer satisfaction. Our comprehensive
                                    inventory of micro to macro spare parts enables swift and efficient repairs for various coffee
                                    equipment, including coffee machines, grinders, water filtration, kettles, scales, and more.
                                </div>
                                <div className="images-group">
                                    {/* <img src={backArrow} alt="backward-arrow" /> */}
                                    <img src={ascasso} alt="ascasso" />
                                    <img src={baratza} alt="baratza" />
                                    <img src={hario} alt="hario" />
                                    <img src={bunn} alt="BUNN" />
                                    <img src={nespresso} alt="Nespresso" />
                                    <img src={simonelli} alt="simonelli" />
                                    {/* <div className="client-img"><img src={ascasso} alt="ascasso" /></div>
                            <div className="client-img"><img src={baratza} alt="baratza" /></div>
                            <div className="client-img"><img src={hario} alt="hario" /></div>
                            <div className="client-img"><img src={bunn} alt="BUNN" /></div>
                            <div className="client-img"><img src={nespresso} alt="Nespresso" /></div>
                            <div className="client-img"><img src={simonelli} alt="simonelli" /></div> */}
                                    {/* <img src={forwardArrow} alt="forward-arrow" /> */}
                                </div>
                            </div>
                        </Container>
                    </div>

                    <div className="offering-type">
                        <Container>
                            <div className="Title-offering">
                                Types of Offerings
                            </div>
                            <div className="description-offering">
                                <div className="description-offering-text">
                                    We take service standards seriously, ensuring the highest customer satisfaction. Our comprehensive
                                    inventory of micro to macro spare parts enables swift and efficient repairs for various coffee
                                    equipment, including coffee machines, grinders, water filtration, kettles, scales, and more.
                                </div>
                            </div>
                            <div className="cards-group">
                                <div className="card">
                                    <div className="card-img">
                                        <img src={exprtAdvice} alt="expert-advice" />
                                    </div>
                                    <div className="offering-name">Expert Advice</div>
                                </div>
                                <div className="card">
                                    <div className="card-img">
                                        <img src={amc} alt="amc" />
                                    </div>
                                    <div className="offering-name">AMC</div>
                                </div>
                                <div className="card">
                                    <div className="card-img">
                                        <img src={healthCheck} alt="health-check" />
                                    </div>
                                    <div className="offering-name">Preventive Health Checkup</div>
                                </div>
                                <div className="card">
                                    <div className="card-img">
                                        <img src={installation} alt="installation" />
                                    </div>
                                    <div className="offering-name">Installation</div>
                                </div>

                            </div>
                        </Container>
                    </div>

                    <div className="footer-block-section">
                        <Container>
                            <div className="Footer">
                                <div className="Title-footer">
                                    Experience the Difference
                                </div>
                                <div className="footer-description">
                                    Contact us today to keep your coffee machines in perfect working condition, ensuring you can always
                                    brew the perfect taste.
                                </div>
                                <Button variant="contained" sx={{ backgroundColor: "#00B4D7", width: "100%" }} className='add-btn contact-us-btn' onClick={handleContactModalOpen}>
                                    <Typography color="white" style={{ textTransform: "capitalize" }}>
                                        Contact Us
                                    </Typography>
                                </Button>
                            </div>
                        </Container>
                    </div>

                </div>
            </div>
            {showContactModal && (
                <ContactModal
                    open={showContactModal}
                    onClose={handleContactModalClose}
                    onSubmit={handleFormSubmit}
                />
            )}
        </>
    )
}

export default ServicePage