import { gql } from "@apollo/client";

export const GET_RATINGS_METADATA = gql`
  query {
    productReviewRatingsMetadata {
      items {
        id
        name
        values {
          value
          value_id
        }
      }
    }
  }
`;