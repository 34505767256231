// TabbyProductWidget.js
import React, { useContext, useEffect } from "react";
import "./postPayStip.scss"
import { mainContext } from "../../MainComponent";
import { useTranslation } from "react-i18next";

console.log("tabby here........")

const TabbyProductWidget = (props) => {
  const { store } = useContext(mainContext); 
  const { t } = useTranslation();
  const storePath = window.location.pathname;
  const selectedStores =
  store && store !== "" ? store : storePath?.split("/")?.[1];
  var amt = parseInt((props.amt / 100) * 100);
  console.log("props_page: ",props.page)
  amt = parseInt((props.amt / 100) * 100);
  const lang = props.store.split('_')[1];
  let merchantCode = "uae_AED";
  if(props.store=="sa_ar" || props.store=="sa_en") merchantCode = "ksa_SAR";

  const tabbyPromoScript = document.createElement("script");
  tabbyPromoScript.async = true;
  tabbyPromoScript.src = "https://checkout.tabby.ai/tabby-promo.js";
  document.head.appendChild(tabbyPromoScript);
  useEffect(() => {
    tabbyPromoScript.addEventListener('load', (e) => {
        window.TabbyPromo({
          selector: '#TabbyPromo', // required, content of tabby Promo Snippet will be placed in element with that selector. 
          currency: props.currency, // required, currency of your product. AED|SAR|KWD|BHD|QAR only supported, with no spaces or lowercase.
          price: amt, // required, price or the product. 2 decimals max for AED|SAR|QAR and 3 decimals max for KWD|BHD.
          installmentsCount: 4, // Optional, for non-standard plans.
          lang: lang, // Optional, language of snippet and popups, if the property is not set, then it is based on the attribute 'lang' of your html tag.
          source: 'product', // Optional, snippet placement; `product` for product page and `cart` for cart page.
          publicKey: 'pk_785f0412-0c88-4dda-b53c-9506ec8a5bf8', // required, store Public Key which identifies your account when communicating with tabby.
          merchantCode: merchantCode // required
        });
      });
  });
  const dataLocale = selectedStores === 'ae_en' || selectedStores === 'ae_er';
    return (
    <>
      {dataLocale && props.page === 'detail' ?
        <>
          <div id="TabbyPromo"></div>
        </>
        :
        <>
          <div id="TabbyPromo"></div>
        </>
      }
    </>
  );
};
export default TabbyProductWidget;