import { gql } from '@apollo/client';

export const SIMPLE_CATEGORY_PRODUCTLIST = gql`
  query getCategoryProductsList($categoryId: String!, $currentPage: Int!, $sortOrder: SortEnum) {
    products(filter: {category_id: {eq: $categoryId}}, pageSize: 48, currentPage: $currentPage, sort: {price: $sortOrder}) {
      items {
        url_key
        name
        sku
        meta_title
        meta_keyword
        meta_description
        only_x_left_in_stock
        quantity
        stock_status
        ... on ConfigurableProduct {
          configurable_options {
            id
            attribute_id_v2
            label
            position
            use_default
            attribute_code
            values {
              value_index
              label
            }
            product_id
          }
        }
        review_count
      rating_summary
      reviews {
          items {
              average_rating
              summary
              text
              created_at
              nickname
          }
      }
        price_range {
          minimum_price {
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
            discount {
              amount_off
              percent_off
            }
          }
          maximum_price {
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
            discount {
              amount_off
              percent_off
            }
          }
        }
        image {
          small_url
          url
          label
        }
        small_image{
            url
            label
        }
        media_gallery {
            url
            label
            small_url
            ... on ProductVideo {
                video_content {
                    media_type
                    video_provider
                    video_url
                    video_title
                    video_description
                    video_metadata
                }
            }
        }
        short_description {
            html
        }
      }
      page_info {
        page_size
        current_page
        total_pages
      }
      aggregations {
        attribute_code
        count
        label
        options {
          label
          value
          count
        }
      }
    }
  }
`;

export const SIMPLE_BRANDS_PRODUCTLIST = gql`
  query getCategoryProductsList($categoryId: String!, $currentPage: Int!, $manufacturer: String!) {
    products(filter: {category_id: {eq: $categoryId}, manufacturer: {eq: $manufacturer}}, pageSize: 48, currentPage: $currentPage) {
      items {
        url_key
        name
        sku
        meta_title
        meta_keyword
        meta_description
        only_x_left_in_stock
        quantity
        stock_status
        ... on ConfigurableProduct {
          configurable_options {
            id
            attribute_id_v2
            label
            position
            use_default
            attribute_code
            values {
              value_index
              label
            }
            product_id
          }
        }
        review_count
      rating_summary
      reviews {
          items {
              average_rating
              summary
              text
              created_at
              nickname
          }
      }
        price_range {
          minimum_price {
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
            discount {
              amount_off
              percent_off
            }
          }
          maximum_price {
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
            discount {
              amount_off
              percent_off
            }
          }
        }
        image {
          small_url
          url
          label
        }
        small_image{
            url
            label
        }
        media_gallery {
            url
            label
            small_url
            ... on ProductVideo {
                video_content {
                    media_type
                    video_provider
                    video_url
                    video_title
                    video_description
                    video_metadata
                }
            }
        }
        short_description {
            html
        }
      }
      page_info {
        page_size
        current_page
        total_pages
      }
      aggregations {
        attribute_code
        count
        label
        options {
          label
          value
          count
        }
      }
    }
  }
`;

export const GET_BRANDS_LIST = gql`
  query {
    customAttributeMetadata(
      attributes: [
        {
          attribute_code: "manufacturer"
          entity_type: "catalog_product"
        }
      ]
    ) {
      items {
        attribute_code
        attribute_type
        entity_type
        input_type
        attribute_options {
        value
        label
        swatch_image
      }
        storefront_properties {
          use_in_product_listing
          use_in_layered_navigation
          use_in_search_results_layered_navigation
          visible_on_catalog_pages
          position
        }
      }
    }
  }
`;
export const GET_PRODUCTS_FILTER = gql`
query GetFilterProducts($categoryId: String!, $pageSize: Int!, $manufacturerName: [String!], $from: String!, $to: String) {
    products(filter: {category_id: {eq: $categoryId}, manufacturer:{in:$manufacturerName}, price:{from:$from,to:$to}}, pageSize: $pageSize) {
        items {
          name
          delivery_estimation
          top_badge_left
          top_badge_left_title
          top_badge_right
          top_badge_right_title
          sku
          url_key
          product_manufacturer
          stock_status
          review_count
            rating_summary
            reviews {
                items {
                    average_rating
                    summary
                    text
                    created_at
                    nickname
                }
            }
            small_image {
                disabled
                label
                position
                url
                }
            ... on ConfigurableProduct {
                    media_gallery {
                        url
                        label
                        position
small_url
                    }
                    variants {
                        attributes {
                            uid
                            label
                            code
                        }
                        product {
                            name
                            sku
                            price_range {
                                minimum_price {
                                    regular_price {
                                        value
                                        currency
                                    }
                                }
                            }
                            small_image 
                            {
                                label
                                url
                            }
                            image 
                            {
                                label
                                url
small_url
                            }
                            
                        }
                    }
                    configurable_options{
                        values {
                            label
                            swatch_data{
                                value
                            }
                        }
                    }
    
            }
            price_range {
                minimum_price {
                regular_price {
                    value
                    currency
                }
                }
            }
            image {
                url
                label
small_url

            }
            small_image{
                url
                label
            }
        }
        page_info {
          page_size
          current_page
          total_pages

        }
        aggregations {
          attribute_code
          count
          currency
          label
          options {
            label
            value
            count
          }
        }
      }

  }
`;

export const GET_CATEGORY_DETAILS = gql`
query GetCategoryDetails($id: Int!) {
  category(id: $id) {
    id
    include_in_menu
    level
    name
    path
    url_path
    url_key
    meta_title
    meta_keywords
    meta_description
    children_count
    children_count
    children {
      id
      level
      name
      path
      children {
        id
        level
        name
        path
        children {
          id
          level
          name
          path
          children {
            id
            level
            name
            path
          }
        }
      }
    }
    breadcrumbs {
      category_level
      category_name
      category_url_path
  }
  }

  }
`;

export const BLOG_LIST = gql`
  query {
    getAllPosts {
      banner
      banner_url
      items {
          thumbnail
          title
          short_description
          link
      }
    }
  }
`;

export const BLOG_DETAIL = gql`
  query($url_key: String!) {
    getSingleBlog(url_key: $url_key) {
      banner
      title
      description
      link
      recentPosts {
          title
          link
      }
    }
  }
`;