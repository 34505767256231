import { useEffect, useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Container } from "@material-ui/core";
import * as mutations from "../../../operations/mutations";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { CountryContext, mainContext } from "../MainComponent";

interface FWProps {
  onClose: () => void;
}
// let ReactSimpleCaptcha: any = require('react-simple-captcha');
function ForgotPassword(props: FWProps) {
  const { t } = useTranslation();
  const { store } = useContext(CountryContext);
  const [email, setEmail] = useState("");
  const [successFull, setsuccessFull] = useState(false)
  const loggedInToken = localStorage.getItem("loggedInCust");

  useEffect(() => {
    window?.scrollTo(0, 0);
  },[])

  const [requestPasswordResetEmail] = useMutation(
    mutations.customerMutation.REQUEST_PASS_RESET_EMAIL,
    {
      context: {
        headers: {
          Connection: "keep-alive",
          store: store
        },
      },
    }
  );

  const hanldeResetPwd = () => {
    requestPasswordResetEmail({
      variables: {
        email: email,
      },
    }).then((res: any) => {
      setsuccessFull(res?.data?.requestPasswordResetEmail)
      props.onClose()
    }).catch((error: any) => {
      console.error(error)
    })
  }

  return (
    <div className="login-wrapper">
      <div className="reg-content-wrap">
        <Container component="main" maxWidth="md">
          <div>
          </div>
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid container spacing={{ xs: 0, md: 8, lg: 8 }} justifyContent="center">
              <Grid item lg={10} md={10} xs={12}>
                <Card
                  sx={{
                    maxWidth: "100%",
                    boxShadow: "none",
                    border: "1px solid #E0DEDE",
                    mb: 3,
                    p: 2,
                  }}
                >
                  <CardContent>
                    <Typography
                      gutterBottom
                      component="h6"
                      variant="h5"
                      sx={{ fontWeight: 700, color: "#000" }}
                    >
                      {t("auth.forgotPwd.enter_email")}
                    </Typography>

                    <Box
                      component="form"
                      noValidate
                      autoComplete="off"
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            placeholder="Email"
                            required
                            fullWidth
                            id="email"
                            name="email"
                            value={email}
                            autoComplete="off"
                            onChange={(e: any) => {setEmail(e.target.value)}}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            fullWidth
                            onClick={hanldeResetPwd}
                            variant="contained"
                            disableElevation
                            sx={{
                              my: 2,
                              textTransform: "capitalize",
                              color: "#fff",
                              fontSize: "18px",
                              fontWeight: "500",
                              padding: "6px 34px",
                            }}
                          >
                            {t("auth.forgotPwd.reset_my_password")}
                          </Button>
                        </Grid>
                        <Typography color="green">
                          {successFull && t("auth.forgotPwd.success-msg")}
                        </Typography>
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
    </div>
  );
}
export default ForgotPassword;
