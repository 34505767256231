import React from 'react';
import './PaymentFalier.css'

interface IPaymentFailur {
    isOpen: boolean,
    onClose: () => void,
    title: string,
    description1: string,
    description2: string
}

const PaymentFailer = (props: IPaymentFailur) => {
    const modalClassName = props?.isOpen ? 'modal-overlay open' : 'modal-overlay';

    return (
        <div className={modalClassName}>
            <div className="modal-content">
                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" /></svg>
                <h2>{props?.title}</h2>
                <p>{props?.description1}</p>
                <p>{props?.description2}</p>
                <button onClick={props?.onClose}>Try Again</button>
            </div>
        </div>
    );
};

export default PaymentFailer;
