import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { useMutation } from "@apollo/client";
import * as mutations from "../../../operations/mutations";
import * as queries from "../../../operations/queries";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import ChangePasswordModal from "./ChangePassModal";
import { useTranslation } from "react-i18next";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));
function MyAccountPage() {
  const { t } = useTranslation();
  const loggedInToken = localStorage.getItem("loggedInCust");
  const [modalOpen, setModalOpen] = useState(false);
  const [demo, setDemo] = useState(false);
  const handledemostate = () => {
    setDemo(true);
  };

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [changePassword, { data: changePassRes, error: changePassError }] =
    useMutation(mutations.customerMutation.CHANGE_PASSWORD_MUTATION, {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    });

  const { data: custDetailsDataRes } = useQuery(
    queries.customerQueries.GET_CUSTOMER_INFO,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );

  return (
    <>
      <Box sx={{ width: "100%", bgcolor: "#F0F2F2", mb: 3, p: 1 }}>
        <Typography
          variant="h5"
          component="h5"
          sx={{ fontWeight: "600", fontSize: "20px" }}
        >
          {t("buyer_dashboard.myAccountPage.my_acnt")}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          bgcolor: "#F7F6F6",
          mb: 3,
          border: "1px solid #D2CDCD",
          p: 1,
        }}
      >
        <Typography
          variant="h6"
          component="h6"
          sx={{ fontSize: "18px", fontWeight: "500" }}
        >
          {t("buyer_dashboard.myAccountPage.acnt_info")}
        </Typography>
      </Box>
      <Box sx={{ width: "100%", bgcolor: "background.paper", mb: 3 }}>
        <Card variant="outlined" sx={{ minWidth: "100%" }}>
          <CardContent sx={{ textAlign: "initial" }}>
            <Typography sx={{ fontSize: 18, fontWeight: 500 }} gutterBottom>
              {t("buyer_dashboard.myAccountPage.contact_info")}
            </Typography>
            <Typography
              variant="h6"
              component="div"
              gutterBottom
              sx={{ fontSize: 18, fontWeight: 400 }}
            >
              {custDetailsDataRes?.customer?.firstname}&nbsp;
              {custDetailsDataRes?.customer?.lastname}
            </Typography>
            <Typography
              variant="h6"
              component="div"
              gutterBottom
              sx={{ fontSize: 18, fontWeight: 400 }}
            >
              {custDetailsDataRes?.customer?.email}
            </Typography>
          </CardContent>
          <CardActions sx={{ display: "flex", gap: "25px" }}>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
            >
              {/* <Item><Button size="small">Edit</Button></Item> */}
              <Item>
                {" "}
                <Button size="small" onClick={handleModalOpen}>
                  {t("buyer_dashboard.myAccountPage.chng_pwd")}
                </Button>
                {changePassRes && (
                  <div>{t("buyer_dashboard.myAccountPage.pwd_chng_msg")}</div>
                )}
                {changePassError && (
                  <div>
                    {t("buyer_dashboard.myAccountPage.err")}{" "}
                    {changePassError.message}
                  </div>
                )}
              </Item>
              <ChangePasswordModal
                isModalOpen={modalOpen}
                handleModalClose={handleModalClose}
              />
            </Stack>
          </CardActions>
        </Card>
      </Box>
      <Box sx={{ width: "100%", bgcolor: "#fff", mb: 3 }}>
        <Card variant="outlined" sx={{ minWidth: "100%" }}>
          <CardContent sx={{ textAlign: "initial" }}>
            <Typography sx={{ fontSize: 18, fontWeight: 500 }} gutterBottom>
              {t("buyer_dashboard.myAccountPage.newsletter")}
            </Typography>
            <Typography
              variant="h6"
              component="div"
              gutterBottom
              sx={{ fontSize: 18, fontWeight: 400 }}
            >
              {t("buyer_dashboard.myAccountPage.not_subscribed")}
            </Typography>
          </CardContent>
          <CardActions sx={{ display: "flex", gap: "25px" }}>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
            >
              <Item>
                <Link to="newsletter">
                  <Button size="small">
                    {t("buyer_dashboard.myAccountPage.edit_btn")}
                  </Button>
                </Link>
              </Item>
            </Stack>
          </CardActions>
        </Card>
      </Box>
      <Box
        sx={{
          width: "100%",
          bgcolor: "#F7F6F6",
          mb: 3,
          border: "1px solid #D2CDCD",
          display: "flex",
          gap: "25px",
          p: 2,
        }}
      >
        <Typography variant="h6" component="h6">
          {t("buyer_dashboard.myAccountPage.addr_book")}
        </Typography>
        <Typography variant="h6" component="h6">
          {t("buyer_dashboard.myAccountPage.manage_addr")}
        </Typography>
      </Box>
      <Box sx={{ width: "100%", bgcolor: "#fff", mb: 3 }}>
        {custDetailsDataRes?.customer?.addresses.map((data: any, index: any) =>
          data?.default_billing === true ? (
            <Card variant="outlined" sx={{ minWidth: "100%" }}>
              <CardContent sx={{ textAlign: "initial" }}>
                <Typography sx={{ fontSize: 18, fontWeight: 500 }} gutterBottom>
                  {t("buyer_dashboard.myAccountPage.default_bill_addr")}
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  sx={{ fontSize: 18, fontWeight: 400 }}
                >
                  {data?.firstname}&nbsp;{data?.lastname.charAt(0)}
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  sx={{ fontSize: 18, fontWeight: 400 }}
                >
                  {data?.street[0]}
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  sx={{ fontSize: 18, fontWeight: 400 }}
                >
                  {data?.city},
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  sx={{ fontSize: 18, fontWeight: 400 }}
                >
                  {data?.country_code}
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  sx={{ fontSize: 18, fontWeight: 400 }}
                >
                  Mob&nbsp;:&nbsp;{data?.telephone}
                </Typography>
              </CardContent>
              <CardActions sx={{ display: "flex", gap: "25px" }}>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={2}
                >
                  <Item>
                    <Link to="/edit-address-book/edit-billing">
                      <Button size="small">
                        {t("buyer_dashboard.myAccountPage.edit_bill_addr_btn")}
                      </Button>
                    </Link>
                  </Item>
                </Stack>
              </CardActions>
            </Card>
          ) : (
            ""
          )
        )}
      </Box>
      <Box sx={{ width: "100%", bgcolor: "#fff", mb: 3 }}>
        {custDetailsDataRes?.customer?.addresses.map((data: any, index: any) =>
          data?.default_shipping === true ? (
            <Card variant="outlined" sx={{ minWidth: "100%" }}>
              <CardContent sx={{ textAlign: "initial" }}>
                <Typography sx={{ fontSize: 18, fontWeight: 500 }} gutterBottom>
                  {t("buyer_dashboard.myAccountPage.default_ship_addr")}
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  sx={{ fontSize: 18, fontWeight: 400 }}
                >
                  {data?.firstname}&nbsp;{data?.lastname}
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  sx={{ fontSize: 18, fontWeight: 400 }}
                >
                  {data?.street[0]}
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  sx={{ fontSize: 18, fontWeight: 400 }}
                >
                  {data?.city},
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  sx={{ fontSize: 18, fontWeight: 400 }}
                >
                  {data?.country_code}
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  sx={{ fontSize: 18, fontWeight: 400 }}
                >
                  Mob&nbsp;:&nbsp;{data?.telephone}
                </Typography>
              </CardContent>
              <CardActions sx={{ display: "flex", gap: "25px" }}>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={2}
                >
                  {/* <Item><Button size="small">Edit</Button></Item> */}
                  <Item>
                    <Link to="my-account/edit-address-book/edit-shipping">
                      <Button size="small" onClick={handledemostate}>
                        {t("buyer_dashboard.myAccountPage.edit_ship_addr_btn")}
                      </Button>
                    </Link>
                  </Item>
                </Stack>
              </CardActions>
            </Card>
          ) : (
            ""
          )
        )}
      </Box>
    </>
  );
}
export default MyAccountPage;
