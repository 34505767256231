import { gql } from "@apollo/client";


export const GUEST_CART_DATA = gql`
mutation guestCartData {
  createEmptyCart
  }
`;
export const LOGGUSER_CART_DATA = gql`
mutation logUserCartData {
  createEmptyCart
  }
`;


export const ADD_SIMPLE_CART_MUTATION= gql`
mutation postaddsimplecart($cartId: String!, $quantity: Float!, $sku: String!, $seller_id: Int!) {
  addProductsToCart(cartId: $cartId, cartItems: [{ quantity: $quantity, sku: $sku, seller_id:$seller_id, }]) {
    cart {
      items {
        product {
          name
          sku
          sellerData {
            seller_id
            seller_name
          }
        }
        quantity
      }
    }
    user_errors {
      code
      message
    }
  }
  }
`;
export const ADD_CONFIG_PRODUCTS_TO_CART_MUTATION = gql`
  mutation AddConfigurableProductsToCart($input: AddConfigurableProductsToCartInput!) {
    addConfigurableProductsToCart(input: $input) {
      cart {
        items {
          uid
          quantity
          product {
            name
            sku
          }
          ... on ConfigurableCartItem {
            configurable_options {
              option_label
            }
          }
        }
      }
    }
  }
`;
export const REMOVECART_MUTATION= gql`
mutation removeItemFromCart($cartId: String!, $cartItemId: Int!) {
  removeItemFromCart(
    input: {
      cart_id: $cartId,
      cart_item_id: $cartItemId
    }
  )
 {
  cart {
    items {
      id
      product {
        name
      }
      quantity
    }
    prices {
      grand_total{
        value
        currency
      }
    }
  }
 }
  }
`;

export const UPDATE_CART_ITEMS = gql`
mutation UpdateCartItems($cartId: String!, $cartItemUid: ID!, $quantity: Float!) {
  updateCartItems(
    input: {
      cart_id: $cartId,
      cart_items: [
        {
          cart_item_uid: $cartItemUid
          quantity: $quantity
        }
      ]
    }
  ) {
    cart {
      items {
        uid
        product {
          name
        }
        quantity
        prices {
          price {
            value
            currency
          }
          row_total {
            value
            currency
          }
          total_item_discount {
            value
            currency
          }
        }
      }
      prices {
        grand_total {
          value
          currency
        }
      }
    }
  }
}
`;

export const SET_GUEST_EMAIL = gql`
mutation setGuestEmailOnCart($cart_id: String!, $email: String!) {
  setGuestEmailOnCart(
    input: {
      cart_id: $cart_id
      email:  $email
    }
  ) {
    cart {
      email
    }
  }
}
`;

export const ASSIGN_CUST_GUEST_CART = gql`
  mutation assignCustToCart($cart_id: String!) {
    assignCustomerToGuestCart(
      cart_id: $cart_id
    )
  }
`;

export const DOWNLOAD_QUOTATION = `
  mutation DownloadQuotation(
    $cart_id: String!
    $email: String!
    $full_name: String!
    $phone_number: String!
    $vat_number: String!
  ) {
    downloadQuotation(
      cart_id: $cart_id
      email: $email
      full_name: $full_name
      phone_number: $phone_number
      vat_number: $vat_number
    ) {
      download_file
    }
  }
`;
export const MERGE_CARTS = gql`
  mutation MergeCarts($cartId: String!, $destinationCartId: String!) {
    mergeCarts(
      source_cart_id: $cartId
      destination_cart_id: $destinationCartId
    ) {
      applied_coupons {
        code
      }
    }
  }
`;